import React, { useState } from 'react';
import DPAPopup from '../../components/DPAPopup';

function DownloadPage({ setStep }) {
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [dataProcessingAgreement, setDataProcessingAgreement] = useState(false);
    const [open, setOpen] = useState(false);

    const downloadFiles =  (e) => {
    
    }

    const bytesToSize = (bytes) => {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return 'n/a';
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
        if (i === 0) return `${bytes} ${sizes[i]}`;
        return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`;
    };

    const downloadableFiles = [
        {
            name: "file1",
            size: 1000,
            path: "workbook-1.xlsx"
        },
        {
            name: "file2",
            size: 100000,
            path: "workbook-1.xlsx"
        },
    ]

    let purposes = [
        "marketing",
        "analytics",
        "advertising",
        "security",
        "necessary",
        "statistics",
        "other"
    ];

    return (
        <div>
            <header className="text-gray-600 body-font">
                <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
                    <a href="https://datastreams.io" className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
                        <img alt="Datastreams logo" className="h-10" src="https://www.datastreams.io/wp-content/uploads/2019/03/Datastreams_logo_standard.png" />
                    </a>
                    <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-400	flex flex-wrap items-center text-base justify-center">
                        <a href="https://datastreams.io" className="mr-5 hover:text-gray-900">Platform</a>
                        <a href="https://datastreams.io" className="mr-5 hover:text-gray-900">File Transfers</a>
                    </nav>
                </div>
            </header>
            <div className="min-w-screen min-h-screen bg-random flex items-center justify-center px-5 py-5 flex-col">
                <div className="w-full mx-auto rounded-3xl shadow-xl bg-white border-2" style={{ maxWidth: 340 }}>
                    <div style={{cursor: "pointer" }}> 
                        <div className="w-full px-5 pt-8 pb-3">
                            <div className={`${termsAccepted > 0 ? 'hover:border-blue-600': 'hover:border-gray-400'} rounded-full w-44 h-44 mx-auto border-8 border-gray-300 flex items-center justify-center flex-col`}>
                                <div className="w-6 h-6">
                                <svg xmlns="http://www.w3.org/2000/svg" fill={termsAccepted ? "#2564eb": "#000"} viewBox="0 0 640 512"><path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-167l80 80c9.4 9.4 24.6 9.4 33.9 0l80-80c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-39 39V184c0-13.3-10.7-24-24-24s-24 10.7-24 24V318.1l-39-39c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9z"/></svg>
                                </div>
                                <p className="text-xs font-light text-gray-800 leading-tight">Click to download</p>
                            </div>
                        </div>
                        <div className="w-full px-5 text-center pb-8 border-b border-gray-300">
                            <h1 className="text-2xl font-light text-gray-800 leading-tight">Download files</h1>
                            <p className="text-xs text-gray-500">This download will expire in 7 days</p>
                        </div>
                    
                        <div className="w-full px-5 py-3 border-b border-gray-300 text-left">
                            <p className="text-xs font-light text-gray-800 leading-tight">Sender</p>
                            <p className="text-sm text-gray-800 leading-tight">
                                info@datastreams.io
                            </p>
                        </div>

                        <div className="w-full px-5 pt-3 text-left">
                            <p className="text-xs font-light text-gray-800 leading-tight">Files</p>
                        </div>

                        <ul className="border-b border-gray-300">
                            {downloadableFiles.map(file => 
                                <li className="w-full px-5 pb-2" key={file.path}>
                                    <div className="w-full flex">
                                        <div className="flex w-8 items-center">
                                            <div className="w-4 h-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zM432 456c-13.3 0-24-10.7-24-24s10.7-24 24-24s24 10.7 24 24s-10.7 24-24 24z"/></svg>                                        </div>
                                        </div>
                                        <div className="text-left">
                                            <h3 className="text-sm text-gray-800 leading-tight">{file.path}</h3>
                                            <p className="text-xs text-gray-500">{bytesToSize(file.size)}</p>
                                        </div>
                                    </div>
                                </li>
                            )}
                        </ul>
                    </div>   



                    <div className="w-full px-5 py-3 border-b border-gray-300 text-left">
                        <p className="text-xs font-light text-gray-800 leading-tight">Purposes you can use this file for</p>
                        <p className="text-sm text-gray-800 leading-tight">
                            {purposes.join(', ')}
                        </p>
                    </div>

                    <div className="w-full px-5 pt-5">
                        <div className="w-full flex">
                            <div className="flex w-8 items-center">
                                <div className="w-4 h-4">
                                    <input

                                        name="dataProcessingAgreement"
                                        type="checkbox"
                                        className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                                        checked={dataProcessingAgreement}
                                        onChange={e => setDataProcessingAgreement(e.target.checked)}
                                    />

                                </div>
                            </div>
                            <div className="text-left">
                                <label for="dataProcessingAgreement" className="text-sm text-gray-800 leading-tight">I accept the <button onClick={() => setOpen(true)} className="text-blue-600">data processing agreement</button></label>
                            </div>
                        </div>
                    </div>

                    <div className="w-full px-5 pt-3">
                        <div className="w-full flex">
                            <div className="flex w-8 items-center">
                                <div className="w-4 h-4">
                                    <input
                                        name="terms"
                                        type="checkbox"
                                        className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                                        checked={termsAccepted}
                                        onChange={e => setTermsAccepted(e.target.checked)}
                                    />

                                </div>
                            </div>
                            <div className="text-left">
                                <label for="terms" className="text-sm text-gray-800 leading-tight">I accept the <a href="https://datastreams.io" className="text-blue-600">terms and conditions</a></label>
                            </div>
                        </div>
                    </div>

                    <div className="w-full px-5 py-5 text-center flex-col">
                        <button onClick={downloadFiles} disabled={!termsAccepted && !dataProcessingAgreement} className={`${termsAccepted && dataProcessingAgreement  ? 'bg-blue-500 hover:bg-blue-600': 'bg-gray-300 hover:bg-gray-400'} text-white rounded-full px-10 py-2 transition-colors`}>Download</button>
                    </div>
                </div>
                <div>
                    <p className="mt-5 text-xs font-light text-white leading-tight text-left">✅ Privacy compliant</p>
                    <p className="mt-1 text-xs font-light text-white leading-tight text-left">✅ Files automatically expire after 7 days</p>
                    <p className="mt-1 text-xs font-light text-white leading-tight text-left">✅ Free up to 10gb of data</p>
                    <a href="mailto:support@datastreams.io" className="mt-5 text-xs font-light text-white leading-tight">Got a question? Email us and we'll help you out!</a>
                </div>

            </div>
            <DPAPopup open={open} setOpen={setOpen} />
        </div>
    );
  }
  
  export default DownloadPage;
  



import './App.css';
import React from 'react';
import UploadPage from './pages/Upload';
import UploadPrivacySettingsPage from './pages/UploadPrivacySettings';
import UploadFileSettingsPage from './pages/UploadFileSettings';
import UploadShareSettingsPage from './pages/UploadShareSettings';
import DownloadPage from './pages/Download';
import { Toaster } from 'react-hot-toast';
// import { fines } from './fines';

function App() {
  const [step, setStep] = React.useState(1);

  // const renderFineToast = (fine) => {
  //   toast.custom((t) => (
  //     <div
  //       className={`${
  //         t.visible ? 'animate-enter' : 'animate-leave'
  //       } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
  //     >
  //       <div className="flex-1 w-0 p-4 text-left">
  //         <div className="flex items-start">
  //           <div className="ml-3 flex-1">
  //             <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">DATA FINE NEWS | {fine["Date of Decision"]}</h2>
  //             <p className="text-m font-medium text-gray-900">
  //               {fine["Fine [€]"]} fine for {fine["Controller/Processor"]}
  //             </p>
  //             <p className="mt-1 text-sm text-gray-500">
  //               {fine["Type"]} - {fine["Sector"]}
  //             </p>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="flex border-l border-gray-200">
  //         <button
  //           onClick={() => toast.dismiss(t.id)}
  //           className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-blue-500 hover:text-blue-600 focus:outline-none focus:ring-2 focus:ring-indigo-500"
  //         >
  //           Close
  //         </button>
  //       </div>
  //     </div>
  //   ), { duration: 12000 })
  // }

  // useEffect(() => {
  //   setTimeout(() => {
  //     const random = Math.floor(Math.random() * fines.length);
  //     renderFineToast(fines[random])
  //   }, 4000)

  //   setInterval(() => {
  //     const random = Math.floor(Math.random() * fines.length);
  //     renderFineToast(fines[random])
  //   }, 22000);
  // }, []);

  return (
    <div className="App">
        {step === 1 && <UploadPage setStep={setStep} />}
        {step === 2 && <UploadPrivacySettingsPage setStep={setStep} />}
        {step === 3 && <UploadFileSettingsPage setStep={setStep} />}
        {step === 4 && <UploadShareSettingsPage setStep={setStep} />}
        {step === 5 && <DownloadPage setStep={setStep} />}
        <div className="flex items-end justify-end fixed bottom-0 right-0 mb-4 mr-4 z-10">
          <div>
              <a title="Want know more about privacy and data protection visit our website" href="https://datastreams.io" target="_blank" rel="noreferrer" className="block w-16 h-16 bg-white rounded-full transition-all shadow hover:shadow-lg transform hover:scale-110 hover:rotate-12">
                  <img alt="Datastreams logo" className="object-cover object-center w-full h-full rounded-full p-2" src="./datastreams_logo.png" />
              </a>
          </div>
        <Toaster
          position="top-right"
        />
      </div>
    </div>
  );
}

export default App;


function UploadFileSettingsPage({ setStep }) {
    // toggle all checkbox fields with name purpse
    const toggleColumns = (e) => {
        const checkboxes = document.getElementsByName('columns');
        checkboxes.forEach((checkbox) => {
            if (checkbox.checked) {
                checkbox.checked = false;
            } else {
                checkbox.checked = true;
            }
        });
    }

    const columns = [
        "ContractNaam",
        "Produkt Status",
        "Besteldatum",
        "AfgesprokenVertrekDatumTijd",
        "AfspraakType",
        "Boekingswijze",
        "AfgesprokenAankomstDatumTijd",
        "CallCenterRitID",
        "AnnulerenDatumTijdCallcenter",
        "AnnulerenDatumTijdVervoerder",
        "Vervoerder",
        "Kenteken",
        "Pasnummer",
        "PashouderAchternaam",
        "VertrekSynoniem",
        "VertrekAdres",
        "VertrekPostcode",
        "VertrekPlaats",
        "VertrekGPSLat",
        "VertrekGPSLong",
        "BestemmingsSynoniem",
        "BestemmingsAdres",
        "BestemmingsPostcode",
        "BestemmingsPlaats",
        "BestemmingsGPSLat",
        "BestemmingsGPSLong",
        "DeclarabeleKms",
        "Personen",
        "BegeleiderMedisch",
        "BegeleiderOV",
        "Meereizende",
        "Kinderen Gratis",
        "Kinderen 50%",
        "SOHO",
        "RolstoelDuw",
        "RolstoelElektrisch",
        "RolstoelOpvouwbaar",
        "Scootmobiel",
        "Rollator",
        "Kinderwagen",
        "Bagage",
        "TaxibusIndicatie",
        "TaxiIndicatie",
        "VoorinIndicatie",
        "IndividueelVervoer",
        "KamerTotKamerIndicatie",
        "VisueleHandicap",
        "Slechthorend",
        "Ovadvies",
        "InstapVoorrijdenDatumTijd",
        "InstapDatumTijd",
        "InstapGPSLat",
        "InstapGPSLong",
        "InstapAfwijking",
        "InstapActiveren",
        "UitstapVoorrijdenDatumTijd",
        "UitstapDatumTijd",
        "UitstapGPSLat",
        "UitstapGPSLong",
        "UitstapAfwijking",
        "UitstapActiveren",
        "KmRit",
        "ReisduurWerkelijk",
        "ReisduurReferentie",
        "ReisduurReferentieFactorRelatief",
        "ReisduurReferentieFactorAbsoluut",
        "ReisduurMaximaal",
        "ReisduurCorrect",
        "StiptheidCorrect",
        "DataRit",
        "Loosmelding",
        "LoosmeldingReden",
        "LoosmeldingDatumTijd",
        "LoosmeldingGPSLat",
        "LoosmeldingGPSLong",
        "LoosmeldingAfwijking",
        "LoosmeldingActiveren",
        "LoosmeldingOpmerking",
        "Opmerkingen",
        "OpmerkingenIntern",
    ]

    return (
        <div>
            <header className="text-gray-600 body-font">
                <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
                    <a href="https://datastreams.io" className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
                        <img alt="Datastreams logo" className="h-10" src="https://www.datastreams.io/wp-content/uploads/2019/03/Datastreams_logo_standard.png"/>
                    </a>
                    <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-400	flex flex-wrap items-center text-base justify-center">
                        <a href="https://datastreams.io" className="mr-5 hover:text-gray-900">Platform</a>
                        <a href="https://datastreams.io" className="mr-5 hover:text-gray-900">File Transfers</a>
                    </nav>
                </div>
            </header>
            <div className="min-w-screen min-h-screen bg-random flex items-center justify-center px-5 py-5 flex-col">
                <div className="w-full mx-auto rounded-3xl shadow-xl bg-white border-2 p-5" style={{ maxWidth: 300 }}>
                    <h1 className="text-2xl font-light text-gray-800 leading-tight">Sharing conditions</h1>
                    <p className="text-xs text-gray-500 mt-2">You can leave out columns that contain privacy sensitive data.</p>

                    <div className="pt-4 text-left">
                        <div className="flex">
                            <div className="w-1/2">
                                <p className="text-xs font-light text-gray-800 leading-tight">Sharing columns</p>
                            </div>
                            <div className="w-1/2 text-right">
                                <p className="text-xs font-light text-gray-800 leading-tight"><button onClick={toggleColumns} className="underline"  style={{cursor: "pointer" }}>Toggle all</button></p>
                            </div>
                        </div>

                        <div className="" style={{height: 200, 'overflowY': 'scroll'}}>
                            {columns.map((column) => (
                                <div className="my-2">
                                    <label className="inline-flex items-center text-xs">
                                    <input
                                        type="checkbox"
                                        name="columns"
                                        value={`${column}_column`}
                                        className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50"
                                        defaultChecked
                                    />
                                    <span className="ml-2">{column}</span>
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="border-b border-gray-300 px-5 py-2"></div>

                    <div className="w-full px-5 pt-5 text-center flex-col">
                        <button onClick={() => setStep(4)} className={`bg-blue-500 hover:bg-blue-600 text-white rounded-full px-10 py-2 transition-colors`}>Next step (3/4)</button>
                        <button onClick={() => setStep(4)} className={`text-xs mt-4 underline`}>Skip settings & send directly</button>
                    </div>

                </div>
                <div>
                    <p className="mt-5 text-xs font-light text-white leading-tight text-left">✅ Privacy compliant</p>
                    <p className="mt-1 text-xs font-light text-white leading-tight text-left">✅ Files automatically expire after 7 days</p>
                    <p className="mt-1 text-xs font-light text-white leading-tight text-left">✅ Free up to 10gb of data</p>
                    <a href="mailto:support@datastreams.io" className="mt-5 text-xs font-light text-white leading-tight">Got a question? Email us and we'll help you out!</a>
                </div>

            </div>
        </div>
    );
  }
  
  export default UploadFileSettingsPage;
  



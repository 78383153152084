
function UploadShareSettingsPage({ setStep }) {
    // toggle all checkbox fields with name purpse

    // function to copy share_url
    const copyShareUrl = () => {
        const shareUrl = document.getElementById('share_url');
        shareUrl.select();
        document.execCommand('copy');
    }


    return (
        <div>
            <header className="text-gray-600 body-font">
                <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
                    <a href="https://datastreams.io" className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
                        <img alt="Datastreams logo" className="h-10" src="https://www.datastreams.io/wp-content/uploads/2019/03/Datastreams_logo_standard.png"/>
                    </a>
                    <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-400	flex flex-wrap items-center text-base justify-center">
                        <a href="https://datastreams.io" className="mr-5 hover:text-gray-900">Platform</a>
                        <a href="https://datastreams.io" className="mr-5 hover:text-gray-900">File Transfers</a>
                    </nav>
                </div>
            </header>
            <div className="min-w-screen min-h-screen bg-random flex items-center justify-center px-5 py-5 flex-col">
                <div className="w-full mx-auto rounded-3xl shadow-xl bg-white border-2 p-5" style={{ maxWidth: 300 }}>
                    <h1 className="text-2xl font-light text-gray-800 leading-tight">Sharing options</h1>
                    <p className="text-xs text-gray-500 mt-2">You can choose to send your file via email or via link or both!</p>

                    <p className="pt-4 text-xs text-left font-light text-gray-800 leading-tight">Send an email</p>

                    <div className="pt-2 text-left">
                        <div className="flex flex-col">
                            <input type="email" id="email_to" name="email_to" placeholder="Email to" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>
                    </div>

                    <div className="pt-2 text-left">
                        <div className="flex flex-col">
                            <input type="email" id="email_from" name="email_from" placeholder="Your email" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>
                    </div>

                    <div className="pt-2 text-left">
                        <div className="flex flex-col">
                            <input  id="title" name="title" placeholder="Title" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>
                    </div>

                    <div className="pt-2 text-left">
                        <div className="flex flex-col">
                            <textarea id="message" name="message" placeholder="Message" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"></textarea>
                        </div>
                    </div>


                    <div className="w-full px-5 pt-5 text-center flex-col">
                        <button onClick={() => setStep(5)} className={`bg-blue-500 hover:bg-blue-600 text-white rounded-full px-10 py-2 transition-colors`}>Send</button>
                    </div>

                    <div className="border-b border-gray-300 px-5 py-2"></div>


                    <p className="pt-4 text-xs text-left font-light text-gray-800 leading-tight">Or use a link</p>
                    <div className="pt-2">
                        <div className="flex flex-row">
                            <input type="url" id="share_url" name="share_url" placeholder="Share URL" value="https://trusted-transfer/s932bzv30zsdqp" className="w-full bg-gray-100 bg-opacity-50 rounded-l border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                            <button onClick={copyShareUrl} className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-r">Copy</button>
                        </div>
                        {/* <button onClick={() => setStep(4)} className={`text-xs mt-4 underline`}>Show link statistics 🔒</button> */}
                    </div>

                </div>
                <div>
                    <p className="mt-5 text-xs font-light text-white leading-tight text-left">✅ Privacy compliant</p>
                    <p className="mt-1 text-xs font-light text-white leading-tight text-left">✅ Files automatically expire after 7 days</p>
                    <p className="mt-1 text-xs font-light text-white leading-tight text-left">✅ Free up to 10gb of data</p>
                    <a href="mailto:support@datastreams.io" className="mt-5 text-xs font-light text-white leading-tight">Got a question? Email us and we'll help you out!</a>
                </div>

            </div>
        </div>
    );
  }
  
  export default UploadShareSettingsPage;
  



import {useDropzone} from 'react-dropzone';

function UploadPage({ setStep }) {
    const {acceptedFiles, getRootProps, getInputProps, open} = useDropzone({ maxFiles: 1 });

    const transferFiles =  (e) => {
        e.preventDefault();

        if (acceptedFiles.length > 0) {
            setStep(2);
        } else {
            open();
        }
    }

    // bytes to human readable
    const bytesToSize = (bytes) => {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return 'n/a';
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
        if (i === 0) return `${bytes} ${sizes[i]}`;
        return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`;
    };

    return (
        <div>
            <header className="text-gray-600 body-font">
                <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
                    <a href="https://datastreams.io" className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
                        <img alt="Datastreams logo" className="h-10" src="https://www.datastreams.io/wp-content/uploads/2019/03/Datastreams_logo_standard.png" />
                    </a>
                    <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-400	flex flex-wrap items-center text-base justify-center">
                        <a href="https://datastreams.io" className="mr-5 hover:text-gray-900">Platform</a>
                        <a href="https://datastreams.io" className="mr-5 hover:text-gray-900">File Transfers</a>
                    </nav>
                </div>
            </header>
            <div className="min-w-screen min-h-screen bg-random flex items-center justify-center px-5 py-5 flex-col">
                <div className="w-full mx-auto rounded-3xl shadow-xl bg-white border-2" style={{ maxWidth: 300 }}>
                    <div {...getRootProps({className: 'dropzone'})} style={{cursor: "pointer" }}> 
                    <div className="w-full px-5 pt-8 pb-3">
                        <div className="rounded-full w-44 h-44 mx-auto border-8 border-gray-300 flex items-center justify-center flex-col  hover:border-blue-600">
                            <input {...getInputProps()} />
                            <div className="w-6 h-6">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="#2564eb" viewBox="0 0 640 512">
                                <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"/>
                            </svg>
                            </div>
                            <p className="text-xs font-light text-gray-800 leading-tight">Click/or drop file</p>
                        </div>
                    </div>
                    <div className="w-full px-5 text-center pb-8 border-b border-gray-300">
                        <h1 className="text-2xl font-light text-gray-800 leading-tight">Upload your file</h1>
                        <p className="text-xs text-gray-500">Upload will expire in 7 days</p>
                    </div>
                    <ul>
                        {acceptedFiles.map(file => 
                            <li className="w-full px-5 py-2 border-b border-gray-300" key={file.path}>
                                <div className="w-full flex">
                                    <div className="flex w-8 items-center">
                                        <div className="w-4 h-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M175 175C184.4 165.7 199.6 165.7 208.1 175L255.1 222.1L303 175C312.4 165.7 327.6 165.7 336.1 175C346.3 184.4 346.3 199.6 336.1 208.1L289.9 255.1L336.1 303C346.3 312.4 346.3 327.6 336.1 336.1C327.6 346.3 312.4 346.3 303 336.1L255.1 289.9L208.1 336.1C199.6 346.3 184.4 346.3 175 336.1C165.7 327.6 165.7 312.4 175 303L222.1 255.1L175 208.1C165.7 199.6 165.7 184.4 175 175V175zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                                        </div>
                                    </div>
                                    <div className="text-left">
                                        <h3 className="text-sm text-gray-800 leading-tight">{file.path}</h3>
                                        <p className="text-xs text-gray-500">{bytesToSize(file.size)}</p>
                                    </div>
                                </div>
                            </li>
                        )}
                    </ul>
                    </div>
                    <div className="w-full px-5 py-5 text-center flex-col">
                        <button onClick={transferFiles} className={`${acceptedFiles.length > 0 ? 'bg-blue-500': 'bg-gray-300'} hover:bg-blue-600 text-white rounded-full px-10 py-2 transition-colors`}>{acceptedFiles.length > 0 ? "Transfer file" : "Select file"}</button>
                    </div>
                </div>
                <div>
                    <p className="mt-5 text-xs font-light text-white leading-tight text-left">✅ Privacy compliant</p>
                    <p className="mt-1 text-xs font-light text-white leading-tight text-left">✅ Files automatically expire after 7 days</p>
                    <p className="mt-1 text-xs font-light text-white leading-tight text-left">✅ Free up to 10gb of data</p>
                    <a href="mailto:support@datastreams.io" className="mt-5 text-xs font-light text-white leading-tight">Got a question? Email us and we'll help you out!</a>
                </div>

            </div>
        </div>
    );
  }
  
  export default UploadPage;
  


